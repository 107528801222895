<template>
  <div id="back_color">
    <!--  图标链接  -->
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <!-- 顶部栏 -->
    <el-header style="height: 60px">
      <home-topbar
        :is-home="false"
        :is-personal="false"
        :is-notification="true"
        @dialoghover="mydialog"
      ></home-topbar>
    </el-header>
    <div class="main_wrap">
      <!-- 标题栏 -->
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: end;
          margin: 15px 0 10px 0;
        "
      >
        <div class="sub_title_top">
          <div style="position: relative; width: fit-content">
            {{ $t("notice.title") }}
            <label v-if="updateNum.all" class="red_point">{{
              updateNum.all
            }}</label>
          </div>

          <span @click="allSeen"
            ><i
              class="iconfont icon-edit_role_name"
              style="margin-right: 5px"
            />{{ $t("notice.btn.read") }}</span
          >
          <span @click="deleteSeen"
            ><i class="iconfont icon-delete-red" style="margin-right: 5px" />{{
              $t("notice.btn.delete")
            }}</span
          >
        </div>
        <div class="sub_title">
          <div
            style="
              color: #707070;
              font-size: 16px;
              margin: 29px 0 10px 0;
              position: relative;
              text-align: right;
            "
          >
            <span style="margin-right: 10px; vertical-align: middle">{{
              $t("notice.weeklyNotification")
            }}</span>
            <el-switch
              v-model="weeklyReportNotify"
              active-color="rgb(19, 206, 102)"
              inactive-color="#DCDFE6"
              @change="setWeeklyReportNotifySwitch"
            >
            </el-switch>
          </div>
        </div>
      </div>
      <el-container>
        <div style="width: 100%">
          <el-table
            :data="card_list"
            style="
              width: 100%;
              width: 100%;
              border-radius: 10px;
              padding: 10px 10px 0 10px;
            "
            :show-header="false"
          >
            <el-table-column prop="topic" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  :style="{
                    color: !scope.row.seen
                      ? 'rgba(42, 130, 228, 1)'
                      : 'rgba(128, 128, 128, 1)',
                  }"
                  class="row_click"
                  @click="seenSingle(scope.row)"
                >
                  <span
                    v-if="
                      scope.row.action === 'customSingleUserFieldChange' ||
                      scope.row.action === 'customMultiUserFieldChange'
                    "
                    >{{
                      `${scope.row.businessData.fieldName}  ${$t(
                        "notice.FieldAssignmentReminder"
                      )}：${scope.row.businessData.nodeKey}`
                    }}</span
                  >
                  <span v-else-if="scope.row.action === 'applyAccessOnDoc'">
                    {{
                      messageType[scope.row.action] +
                      "：" +
                      scope.row.businessData.docTitle
                    }}
                  </span>
                  <span v-else-if="scope.row.action === 'followDocCommented'">
                    {{
                      scope.row.businessData.commentType === "LINE"
                        ? $t("notice.wordComments")
                        : $t("notice.newPageComments")
                    }}{{ "：" + getContent(scope.row) }}
                  </span>
                  <span v-else-if="scope.row.action === 'batchAssign'">
                    {{
                      $t("notice.batchAssign") + "：" + getContent(scope.row)
                    }}
                  </span>
                  <span
                    v-else-if="scope.row.action === 'docInternetPermission'"
                  >
                    {{
                      messageType[scope.row.action] +
                      (scope.row.businessData.internetEdit
                        ? $t("notice.editable")
                        : $t("notice.readable")) +
                      "：" +
                      "(" +
                      getProjectName(scope.row.projectId) +
                      ") " +
                      scope.row.businessData.docTitle
                    }}
                  </span>
                  <span
                    v-else-if="
                      scope.row.action === 'dfmeaOptimization' ||
                      scope.row.action === 'dfmeaOptimizationEnd'
                    "
                  >
                    {{
                      messageType[scope.row.action] +
                      "：" +
                      "(" +
                      getProjectName(scope.row.projectId) +
                      ") " +
                      scope.row.businessData.optimizationTitle
                    }}
                  </span>
                  <span v-else-if="scope.row.action === 'versionReleased'">
                    {{
                      messageType[scope.row.action] +
                      "：" +
                      "(" +
                      getProjectName(scope.row.projectId) +
                      ") " +
                      scope.row.businessData.versionName
                    }}
                  </span>
                  <span
                    v-else-if="
                      scope.row.action === 'changeRequestReviewUnfinished'
                    "
                  >
                    {{
                      messageType[scope.row.action] +
                      "：" +
                      getContent(scope.row)
                    }}
                  </span>
                  <span v-else-if="scope.row.action === 'changeRequest'">
                    {{
                      messageType[scope.row.action] +
                      "：" +
                      getContent(scope.row)
                    }}
                  </span>
                  <span v-else-if="scope.row.action === 'statusTimeOut'">
                    {{
                      messageType[scope.row.action] +
                      "：" +
                      getContent(scope.row)
                    }}
                  </span>
                  <span v-else-if="scope.row.action === 'taraRiskAnalysis'">
                    {{ $t("tara.notice") + scope.row.businessData.nodeKey }}
                  </span>
                  <span v-else-if="scope.row.action === 'shareFileViewRequest'">
                    {{ $t("share.notice") + scope.row.businessData.fileKey }}
                  </span>
                  <span v-else-if="scope.row.action === 'haraSafetyAnalysis'">
                    {{ $t("hara.notice") + scope.row.businessData.nodeKey }}
                  </span>
                  <span v-else-if="scope.row.action === 'changeRequestRelated'">
                    {{ messageType[scope.row.action] + "：" }}
                    <el-tooltip
                      effect="dark"
                      :disabled="
                        !(scope.row.businessData.relatedNodeKeyList.length > 3)
                      "
                      :content="
                        scope.row.businessData.relatedNodeKeyList.join(', ')
                      "
                      placement="bottom"
                    >
                      <span>
                        {{ getContent(scope.row) }}
                      </span>
                    </el-tooltip>
                  </span>

                  <span v-else>{{
                    messageType[scope.row.action] + "：" + getContent(scope.row)
                  }}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="empty" width="100"></el-table-column>
            <el-table-column prop="" width="180" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  :style="{
                    color: !scope.row.seen
                      ? 'rgba(42, 130, 228, 1)'
                      : 'rgba(128, 128, 128, 1)',
                  }"
                >
                  {{ matchUserNickname(scope.row.from, "") }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="sendAt" width="180" show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
      </el-container>
      <div class="page_wrap">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :page-size="12"
          :current-page.sync="pageObj.current"
          :total="pageObj.total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import HomeTopbar from "@/components/homeTopbar";
import {
  get_notifications_list,
  unSeen,
  seenSingle,
  deleteSeen,
  allSeen,
  get_type_list,
  getWeeklyReportNotifySwitch,
  setWeeklyReportNotifySwitch,
} from "@/network/notification/index.js";
import { get_node_use_node_key } from "@/network/node/index";
import { get_file } from "@/network/baseline/index";
import { mapGetters } from "vuex";
import { get_status_list } from "@/network/workflow/index.js";
export default {
  name: "NotificationVue",
  components: { HomeTopbar },
  data() {
    return {
      mydialog: false,
      loading: false,
      pageObj: {
        total: 0,
        current: 1,
      },
      updateNum: {
        all: 0,
      },
      active: "commentAt",
      messageType: {
        assign: this.$t("notice.assign"),
        knowledgeQuickShare: this.$t("notice.knowledgeQuickShare"),
        atInDocument: this.$t("notice.atInDocument"),
        commentAt: this.$t("notice.commentAt"),
        review: this.$t("notice.review"),
        endReview: this.$t("notice.endReview"),
        inviteDeleteProject: this.$t("notice.inviteDeleteProject"),
        endDeleteProject: this.$t("notice.endDeleteProject"),
        ganttStartNotifyAssignee: this.$t("notice.ganttStartNotifyAssignee"),
        ganttStartNotifyCollector: this.$t("notice.ganttStartNotifyCollector"),
        ganttEndNotifyReliedAssignee: this.$t(
          "notice.ganttEndNotifyReliedAssignee"
        ),
        ganttEndNotifyReliedCollector: this.$t(
          "notice.ganttEndNotifyReliedCollector"
        ),
        customSingleUserFieldChange: this.$t(
          "notice.customSingleUserFieldChange"
        ),
        customMultiUserFieldChange: this.$t(
          "notice.customMultiUserFieldChange"
        ),
        applyAccessOnDoc: this.$t("notice.applyAccessOnDoc"),
        reviewEndInFourHours: this.$t("notice.reviewEndInFourHours"),
        docWordCommentAt: this.$t("notice.docWordCommentAt"),
        docCommentAt: this.$t("notice.docCommentAt"),
        followDocChanged: this.$t("notice.followDocChanged"),
        followDocCommented: this.$t("notice.followDocCommented"),
        testPlanEndInOneDay: this.$t("notice.testPlanEndInOneDay"),
        docInternetPermission: this.$t("notice.docInternetPermission"),
        dfmeaOptimization: this.$t("notice.dfmeaOptimization"),
        dfmeaOptimizationEnd: this.$t("notice.dfmeaOptimizationEnd"),
        changeRequestReviewUnfinished: this.$t("changeRequest.title"),
        changeRequest: this.$t("notice.changeRequest"),
        changeRequestRelated: this.$t("notice.changeRequestRelated"),
        statusTimeOut: this.$t("notice.statusTimeOut"),
        versionReleased: this.$t("notice.version"),
      },
      card_list: [],
      // 记录当前状态超时提醒的pid，用于缓存数据
      statusPList: {},
      // 记录需要调接口的pid，防止二次调用
      pidSet: new Set(),
      status_index_List: [],
      nodeKey_index_List: [],
      // 周报通知
      weeklyReportNotify: false,
    };
  },
  computed: {
    ...mapGetters(["token", "user_list", "t_joined_projects"]),
  },
  async mounted() {
    // await this.get_type_list();
    await this.getList();
    if (this.token) {
      this.unSeen();
    }
    this.getWeeklyReportNotifySwitch();
  },
  methods: {
    // 周报通知相关
    getWeeklyReportNotifySwitch() {
      getWeeklyReportNotifySwitch().then((res) => {
        this.weeklyReportNotify = res;
      });
    },
    setWeeklyReportNotifySwitch() {
      setWeeklyReportNotifySwitch().then((res) => {
        this.weeklyReportNotify = res;
      });
    },
    getProjectName(pid) {
      const project = this.t_joined_projects.find((item) => {
        return pid === item.projectId;
      });
      return project.projectName;
    },
    // 格式化评论详情
    format_detail(detail) {
      const arr = JSON.parse(detail);
      let str = "";
      if (arr) {
        arr.forEach((item) => {
          if (item.type === "text") {
            str += item.data;
          }
        });
      }
      return str;
    },
    // 分页跳转
    handleCurrentChange() {
      this.status_index_List = [];
      this.nodeKey_index_List = [];
      this.getList();
    },
    async get_type_list() {
      await get_type_list().then((res) => {
        this.messageType = [];
        res.forEach((item) => {
          this.messageType[item.action] = item.description;
        });
      });
    },
    async getList() {
      this.loading = true;
      this.card_list = [];
      const params = {
        pageNumber: this.pageObj.current - 1,
        pageSize: 12,
      };
      await get_notifications_list(params).then((res) => {
        this.pageObj.total = res.totalElements;
        this.card_list = res.content;
        this.card_list.forEach((item, index) => {
          let cont = "";
          switch (item.action) {
            case "assign":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.topic;
              break;
            case "batchAssign":
              cont = item.businessData.nodeKeyList
                .map((item1, index) =>
                  index === item.businessData.nodeKeyList.length - 1
                    ? item1
                    : item1 + "，"
                )
                .join("");
              break;
            case "knowledgeQuickShare":
              cont = item.businessData.docTitle;
              break;
            case "followDocChanged":
              cont = item.businessData.docTitle;
              break;
            case "followDocCommented":
              cont =
                item.businessData.docTitle + ": " + item.businessData.plainText;
              break;
            case "atInDocument":
              cont = item.businessData.docTitle;
              break;
            case "docCommentAt":
              cont =
                item.businessData.docTitle + ":" + item.businessData.plainText;
              break;
            case "docWordCommentAt":
              cont =
                item.businessData.docTitle + ":" + item.businessData.plainText;
              break;
            case "commentAt":
              cont = "（" + item.businessData.nodeKey + "）";
              JSON.parse(item.businessData.detail).forEach((i) => {
                if (i.type === "at") {
                  cont = cont + " @" + i.data.nickname + " ";
                } else if (i.type === "text") {
                  cont = cont + i.data;
                } else {
                  cont = cont + "[" + this.$t("notice.Images") + "]";
                }
              });
              break;
            case "review":
              cont = item.businessData.reviewProcessTitle;
              break;
            case "endReview":
              cont = item.businessData.reviewProcessTitle;
              break;
            case "inviteDeleteProject":
              cont = item.businessData.projectDto.projectName;
              break;
            case "endDeleteProject":
              cont = item.businessData.projectDto.projectName;
              break;
            case "ganttStartNotifyAssignee":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.topic;
              break;
            case "ganttEndNotifyReliedAssignee":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.topic;
              break;
            case "ganttStartNotifyCollector":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.topic;
              break;
            case "ganttEndNotifyReliedCollector":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.topic;
              break;
            case "versionReleased":
              cont =
                "(" +
                this.getProjectName(item.projectId) +
                ") " +
                item.businessData.versionName;
              break;
            case "changeRequestReviewUnfinished":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.changeRequestName;
              break;
            case "changeRequestRelated":
              cont =
                "（" +
                (item.businessData.relatedNodeKeyList.length > 3
                  ? item.businessData.relatedNodeKeyList.slice(0, 3) + "..."
                  : item.businessData.relatedNodeKeyList) +
                "）" +
                item.businessData.nodeKey;
              break;
            case "statusTimeOut":
              cont = this.getStatusName(
                item.businessData.status,
                item.projectId,
                item.businessData.ruleMinutes,
                item.businessData.nodeKeyList,
                index
              );
              break;
            case "changeRequest":
              cont =
                "（" +
                item.businessData.nodeKey +
                "）" +
                item.businessData.changeRequestName;
          }
          item.content = cont;
        });
        this.loading = false;
      });
    },
    unSeen() {
      unSeen().then((res) => {
        this.updateNum.all = res.unSeenNumber;
        if (res.unSeenNumber) {
          this.$store.commit("SET_NOTICE", true);
        } else {
          this.$store.commit("SET_NOTICE", false);
        }
        this.$store.commit("SET_NOTICE_NUM", res.unSeenNumber);
      });
    },
    seenSingle(item) {
      if (!item.seen) {
        seenSingle(item.notificationId).then((res) => {
          item.seen = true;
          this.unSeen();
        });
      }
      if (
        item.action === "commentAt" ||
        item.action === "assign" ||
        item.action === "customSingleUserFieldChange" ||
        item.action === "customMultiUserFieldChange"
      ) {
        if (!item.businessData.nodeKey) {
          this.$message({
            type: "warning",
            message: this.$t("notice.message"),
          });
          return;
        }
        const { href } = this.$router.resolve({
          name: "node_detail",
          params: {
            projectId: item.projectId,
            nodeKey: item.businessData.nodeKey,
          },
        });
        window.open(href);
      } else if (
        item.action === "review" ||
        item.action === "endReview" ||
        item.action === "reviewEndInFourHours"
      ) {
        if (!item.businessData.fileId) {
          this.$message({
            type: "warning",
            message: this.$t("notice.ThisReviewDoesNotExist"),
          });
          return;
        }
        get_file(item.projectId, item.businessData.fileId).then((result) => {
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${item.projectId}/myMind/${result.key}`,
            query: { process_review: "process_review" },
          });
          window.open(href, "_blank");
        });
      } else if (
        item.action === "inviteDeleteProject" ||
        item.action === "endDeleteProject"
      ) {
        if (!item.businessData.processUrl) {
          this.$message({
            type: "warning",
            message: this.$t("notice.ThisInvitationDoesNotExist"),
          });
          return;
        }
        window.open(item.businessData.processUrl);
      } else if (
        item.action === "ganttStartNotifyAssignee" ||
        item.action === "ganttStartNotifyCollector" ||
        item.action === "ganttEndNotifyReliedAssignee" ||
        item.action === "ganttEndNotifyReliedCollector"
      ) {
        if (!item.businessData.ganttChartId) {
          this.$message({
            type: "warning",
            message: this.$t("notice.TheGanttChartDoesNotExist"),
          });
          return;
        }
        const { href } = this.$router.resolve({
          name: "gantt",
          params: {
            cur_gant_id: item.businessData.ganttChartId,
            projectId: item.projectId,
            chartId: item.businessData.ganttChartId,
          },
          query: {
            itemId: item.businessData.ganttItemUuid,
          },
        });
        window.open(href);
      } else if (
        item.action === "atInDocument" ||
        item.action === "docCommentAt" ||
        item.action === "docWordCommentAt" ||
        item.action === "followDocCommented" ||
        item.action === "applyAccessOnDoc" ||
        item.action === "followDocChanged" ||
        item.action === "docInternetPermission"
      ) {
        if (!item.businessData.documentId) {
          this.$message({
            type: "warning",
            message: this.$t("notice.TheArticleDoesNotExist"),
          });
          return;
        }
        const query = {
          documentId: item.businessData.documentId,
          title: item.businessData.docTitle,
        };
        item.businessData.elementId
          ? (query.elementId = item.businessData.elementId)
          : "";
        item.businessData.commentId
          ? (query.commentId = item.businessData.commentId)
          : "";
        const { href } = this.$router.resolve({
          name: "knowledge",
          params: {
            projectId: item.projectId,
          },
          query: query,
        });
        window.open(href);
      } else if (item.action === "knowledgeQuickShare") {
        const { href } = this.$router.resolve({
          name: "pubKnowledge",
          params: {
            pathId: item.businessData.pathId,
          },
        });
        window.open(href);
      } else if (item.action === "testPlanEndInOneDay") {
        const { href } = this.$router.resolve({
          name: "test",
          params: {
            projectId: item.projectId,
            cur_id: item.businessData.testPlanId,
          },
        });
        window.open(href);
      } else if (item.action === "changeRequestReviewUnfinished") {
        const { href } = this.$router.resolve({
          path: `/${item.projectId}/nodes/key/${item.businessData.nodeKey}?changeRequests=${item.businessData.changeRequestId}`,
        });
        window.open(href, "_blank");
      } else if (item.action === "changeRequest") {
        const { href } = this.$router.resolve({
          path: `/${item.projectId}/nodes/key/${item.businessData.nodeKey}?changeRequests=${item.businessData.changeRequestId}&changeRelated=false`,
        });
        window.open(href, "_blank");
      } else if (item.action === "changeRequestRelated") {
        const { href } = this.$router.resolve({
          path: `/${item.projectId}/nodes/key/${item.businessData.nodeKey}?changeRequests=${item.businessData.changeRequestId}&changeRelated=true`,
        });
        window.open(href, "_blank");
      } else if (item.action === "versionReleased") {
        const { href } = this.$router.resolve({
          path: `/${item.projectId}/home/release/version/${item.businessData.versionId}`,
        });
        window.open(href, "_blank");
      } else if (item.action === "taraRiskAnalysis") {
        get_node_use_node_key(item.projectId, item.businessData.nodeKey).then(
          (res) => {
            get_file(item.projectId, res.fileId).then((ress) => {
              const { href } = this.$router.resolve({
                path: `/${item.projectId}/myMind/${ress.key}`,
                query: {
                  node_key: item.businessData.nodeKey,
                  tara: true,
                },
              });
              window.open(href, "_blank");
            });
          }
        );
      } else if (item.action === "shareFileViewRequest") {
        const { href } = this.$router.resolve({
          path: `/${item.projectId}/myMind/${item.businessData.fileKey}`,
        });
        window.open(href, "_blank");
      } else if (item.action === "haraSafetyAnalysis") {
        get_node_use_node_key(item.projectId, item.businessData.nodeKey).then(
          (res) => {
            get_file(item.projectId, res.fileId).then((ress) => {
              const { href } = this.$router.resolve({
                path: `/${item.projectId}/myMind/${ress.key}`,
                query: {
                  node_key: item.businessData.nodeKey,
                  hara: true,
                },
              });
              window.open(href, "_blank");
            });
          }
        );
      }
    },
    // 删除已读
    deleteSeen() {
      deleteSeen().then((res) => {
        this.getList();
      });
    },
    // 全部标记为已读
    allSeen() {
      allSeen().then((res) => {
        this.getList();
        this.unSeen();
      });
    },
    getContent(row) {
      if (row.action === "reviewEndInFourHours") {
        return row.businessData.reviewProcessTitle;
      } else if (row.action === "testPlanEndInOneDay") {
        return row.businessData.testPlanName;
      } else {
        return row.content;
      }
    },

    async get_status_list(pid, status, ruleMinutes, nodeKeyList, nowIdnex) {
      this.status_index_List.push({ [status]: nowIdnex });
      this.nodeKey_index_List[nowIdnex] = nodeKeyList;
      if (this.pidSet.has(pid)) {
        return;
      } else {
        this.pidSet.add(pid);
        const res = await get_status_list(pid);
        this.statusPList = {
          ...this.statusPList,
          [pid]: res,
        };
        res.forEach((item) => {
          for (let i of this.status_index_List) {
            if (
              typeof i[item.statusId] === "number" &&
              !isNaN(i[item.statusId]) &&
              !this.card_list[i[item.statusId]].content
            ) {
              this.card_list[i[item.statusId]].content =
                "（" +
                item.name +
                "-" +
                this.$t("notice.over") +
                " " +
                parseFloat(ruleMinutes / 60 / 24).toFixed(2) +
                " " +
                this.$t("notice.days") +
                "）" +
                this.nodeKey_index_List[i[item.statusId]].join(",\n");
            }
          }
        });
      }
    },
    getStatusName(status, pid, ruleMinutes, nodeKeyList, nowIdnex) {
      if (!this.statusPList[pid]) {
        this.get_status_list(pid, status, ruleMinutes, nodeKeyList, nowIdnex);
      } else {
        let name = "";
        this.statusPList[pid].forEach((item) => {
          if (item.statusId == status) {
            name = item.name;
            return;
          }
        });
        return (
          "（" +
          name +
          "-" +
          this.$t("notice.over") +
          " " +
          parseFloat(ruleMinutes / 60 / 24).toFixed(2) +
          " " +
          this.$t("notice.days") +
          "）" +
          nodeKeyList.join(",\n")
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main_wrap {
  position: relative;
  height: 88vh;

  .page_wrap {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon {
    margin-right: 5px;
  }

  .red_point {
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 22px;
    background-color: rgba(255, 87, 51, 1);
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    position: absolute;
    right: -18px;
    top: -7px;
  }

  padding: 0 74px;

  .sub_title_top {
    text-align: left;
    color: rgba(112, 112, 112, 1);
    font-size: 24px;
    margin: 29px 0 10px 0;
    position: relative;
    & > span {
      display: inline-block;
      cursor: pointer;
      font-size: 12px;
      &:nth-of-type(1) {
        color: rgba(166, 166, 166, 1);
        margin-right: 20px;
      }

      &:nth-of-type(2) {
        color: rgba(255, 87, 51, 1);
      }
    }
  }

  .sub_title {
    & > span {
      display: inline-block;
      cursor: pointer;

      &:nth-of-type(1) {
        color: rgba(166, 166, 166, 1);
        margin-right: 20px;
      }

      &:nth-of-type(2) {
        color: rgba(255, 87, 51, 1);
      }
    }
  }

  .row_click {
    cursor: pointer;
    //text-decoration: underline;
  }

  .card_flex {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-content: flex-start;
    padding-left: 132px;

    .nodata_class {
      display: block;
      color: rgba(56, 56, 56, 1);
      font-size: 20px;
      height: 60px;
      line-height: 60px;
    }

    & > p {
      width: 100%;
    }

    & > div {
      width: calc(33% - 72px);
      border-radius: 20px;
      background-color: #fff;
      margin: 0 72px 35px 0;
      position: relative;
      padding: 23px 35px;
      cursor: pointer;

      & > label {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: rgba(255, 87, 51, 1);
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
      }

      & > div > p {
        display: flex;
        justify-content: space-between;

        & > span {
          display: inline-block;

          &:nth-of-type(1) {
            color: rgba(0, 0, 0, 1);
            font-size: 16px;
            font-weight: bold;
          }

          &:nth-of-type(2) {
            color: rgba(128, 128, 128, 1);
            width: 150px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            font-size: 14px;
          }
        }
      }
    }
  }
}

#back_color {
  width: 100vw;
  height: 100vh;
  background-color: rgba(240, 242, 245, 1);
}

/**头部样式 */
.el-header {
  background-color: rgba(48, 100, 143, 1);
  width: 100vw;
  padding: 0;
}

/**侧边栏样式 */
.aside_style {
  background-color: rgba(0, 76, 170, 1);
  height: 70vh;

  .checked_left {
    background-color: rgba(255, 255, 255, 0.26);
  }

  & > div {
    cursor: pointer;
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: left;
    padding: 0 0 0 40px;
    font-size: 16px;
    color: #fff;

    & > span {
      position: relative;

      & > label {
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 22px;
        background-color: rgba(255, 87, 51, 1);
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        position: absolute;
        right: -30px;
        top: -10px;
      }
    }
  }
}
</style>
